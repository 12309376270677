import { useIsDark } from "../../utils/hooks";
import { useMemo } from "react";
import { ReactComponent as MandalaDark } from "./new_logo_mandala_dark.svg";
import { ReactComponent as Mandala } from "./new_logo_mandala.svg";

export default function MandalaIcon(props) {
  const isDark = useIsDark();
  const Icon = useMemo(() => (isDark ? MandalaDark : Mandala), [isDark]);
  return <Icon {...props} />;
}
