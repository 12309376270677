import litentry from "./litentry";
import statemine from "./statemine";
import statemint from "./statemint";
import westmint from "./westmint";
import litmus from "./litmus";
import westendCollectives from "./westendCollectives";
import collectives from "./collectives";
import kusama from "./kusama";
import polkadot from "./polkadot";
import mandalaTestnet from "./mandalaTestnet";
import utama from "./utama";
import madya from "./madya";
import vraja from "./vraja";
import garbha from "./garbha";





const chains = {
  kusama,
  polkadot,
  litentry,
  litmus,
  statemine,
  statemint,
  westmint,
  "westend-collectives": westendCollectives,
  collectives,
  garbha,
  mandalaTestnet,
  utama,
  madya,
  vraja,
  
};

export default chains;
