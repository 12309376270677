/* eslint-disable no-console */
import React, { useEffect } from "react";
import HomeLayout from "../components/layout/home";
import Explore from "../components/home/explore";
import Sections from "../components/home/sections";
import { connect, unSubscribeHomepageInfo } from "../services/websocket";
import { useDispatch } from "react-redux";
import {
  setLatestBlocks,
  setLatestSignedTransfers,
} from "../store/reducers/socketSlice";
import styled from "styled-components";
import Overview from "../components/home/overview";
import { m_t } from "../styles/tailwindcss";
import { clearNftList } from "../store/reducers/nftSlice";
import { clearAssetList } from "../store/reducers/assetSlice";


// The SectionsWrapper component is used as a wrapper for the Sections component, which displays various sections on the home page.
const SectionsWrapper = styled.div`
  ${m_t(16)};
`;
const ExplorerWrapper = styled.div`
  display:flex;
  justify-content: center;

`;

// The OverviewWrapper component is used as a wrapper for the Sections component, which displays various sections on the home page.
const OverviewWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justufy-content: center;
  align-items: center;

   @media (max-width: 420px) {
    justufy-content: center;
    align-items: center;

  }
`;

function Home() {
  const dispatch = useDispatch();

    // Establish WebSocket connection and perform necessary cleanup when the component mounts or unmounts
  useEffect(() => {
    // console.log("socket running from home page")
    connect();

    return () => {
      // console.log("socket return")
      unSubscribeHomepageInfo();
      dispatch(setLatestSignedTransfers([]));
      dispatch(setLatestBlocks([]));
      dispatch(clearNftList());
      dispatch(clearAssetList());
    };
  }, [dispatch]);

  return (
    <HomeLayout>
      {/* Search Section */}
      <ExplorerWrapper>
        <Explore /> 
      </ExplorerWrapper>

      {/* Overview Section */}
      <OverviewWrapper>
        <Overview />
      </OverviewWrapper>

      {/* Sections */}
      <SectionsWrapper>
        <Sections />
      </SectionsWrapper>
    </HomeLayout>
  );
}

export default Home;
