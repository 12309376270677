import MandalaIcon from "../../../components/icons/mandalaIcon";
import { governanceModules, treasuryModules } from "./modules";

const madya = {
  name: "Madya",
  icon: <MandalaIcon />,
  identity: "mandala",
  sub: "mandala",
  value: "madya",
  chain: "mandala-mainnet",
  symbol: "KPG",
  decimals: 18,
  color: "#057DCD",
  colorSecondary: "rgba(55, 101, 220, 0.1)",
  buttonColor: "#000000",
  modules: {
    ...treasuryModules,
    ...governanceModules,
  },
  treasuryWebsite: null,
  subSquareWebsite: null,
};

export default madya;
