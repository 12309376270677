import MandalaIcon from "../../../components/icons/mandalaIcon";
import { governanceModules, treasuryModules } from "./modules";

const vraja = {
  name: "Vraja",
  icon: <MandalaIcon />,
  identity: "mandala",
  sub: "mandala",
  value: "vraja",
  chain: "mandala-testnet",
  symbol: "KPGT",
  decimals: 18,
  color: "#057DCD",
  colorSecondary: "rgba(55, 101, 220, 0.1)",
  buttonColor: "#000000",
  modules: {
    ...treasuryModules,
    ...governanceModules,
  },
  treasuryWebsite: null,
  subSquareWebsite: null,
};

export default vraja;
