/* eslint-disable no-console */
import { toPrecision } from "@osn/common";
import { useSelector } from "react-redux";
import { withLoading } from "../../../HOC/withLoading";
import { chainSettingSelector } from "../../../store/reducers/settingSlice";
import { overviewSelector } from "../../../store/reducers/socketSlice";
import { currencify } from "../../../utils";
import AssetSquareIcon from "../../icons/assetSquareIcon";
import BlockSquareIcon from "../../icons/blockSquareIcon";
import ExtrinsicsSquareIcon from "../../icons/extrinsicsSquareIcon";
import FinalizedBlockSquareIcon from "../../icons/finalizedBlockSquareIcon";
import HolderSquareIcon from "../../icons/holderSquareIcon";
import TransferSquareIcon from "../../icons/transferSquareIcon";
import Loading from "../../loadings/loading";
import { StyledPanelTableWrapper } from "../../styled/panel";
import OverviewItem from "./item";
import ValueDisplay from "../../displayValue";
import Tooltip from "../../tooltip";
import NftSquareIcon from "../../icons/nftSquareIcon";
import ParaIdSquareIcon from "../../icons/paraIdSquareIcon";
import { OverviewItemsWrapper, OverviewPanel } from "./styled";
import styled from "styled-components";
import { no_scroll_bar } from "../../../styles";
import { getFinalizeBlock, getLatestBlock, getTotalAccount, getTotalIssuanxe, signedExtrinsics } from "../../../services/chainApi";
import useChain from "../../../utils/hooks/chain/useChain";
import { useEffect, useState } from "react";
import { useChainApi } from "../../../utils/hooks/chain/useChainApi";



const mapLoadingState = (_props) => {
  return {
    loadingStates: [],
    loadingComponent: <Loading />,
  };
};

export const OverviewCard = styled.div`
  background: ${(p) => p.theme.fillPanel};
  border: 1px solid ${(p) => p.theme.strokeBase};
  // box-shadow: ${(p) => p.theme.shadowPanel};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); /* Add box shadow here */
  border-radius: 8px;
  overflow: hidden;
  max-width: 635px;
   @media (max-width: 400px) {
    width: 310px;
  }


`;


export const OverviewCardWrapper = styled.div`
  overflow: hidden;
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  justufy-content: center;
  align-items: center;
  height: 110px;



  @media (max-width: 420px) {
    flex-direction: column;
    justufy-content: center;
    align-items: center;
    height: 100%;

    

  }


`;





const PanelTableScrollArea = styled.div`
  overflow-x: scroll;
  ${no_scroll_bar};
`;

function Overview() {
  const chains = useChain()
  const chainApi = useChainApi();
  const [blockNumber, setBlockNumber] = useState(0)
  const [inssuance, setInssuance] = useState(0)
    const [totalAccount, setTotalAccount] = useState(0)

  const [blockNumberFinalized, setBlockNumberFinalized] = useState(0)



  const overview = useSelector(overviewSelector);
  // console.log("overview",overview)
  const chainSetting = useSelector(chainSettingSelector);
  const { modules = {} } = chainSetting;

  async function displayLatestBlock(){
    const getLatestBlockFromNode = await getLatestBlock(chains)
    // console.log("blocks in overview",getLatestBlockFromNode)
    getLatestBlockFromNode(number => {
    // console.log('number', number.toNumber())
    setBlockNumber(number.toNumber().toLocaleString('en-US'))
    })
  }

  // console.log("inssuance", inssuance)

   async function displayFinalizedBlock(){
    const getFinalizedNumber = await getFinalizeBlock(chains)
    // console.log("blocks in overview",getFinalizedNumber)
    getFinalizedNumber(number => {
    // console.log('number', number.toNumber())
    setBlockNumberFinalized(number.toNumber().toLocaleString('en-US'))
    })
  }

  async function displayTotalAccount(){
    // console.log("display total account...")
    const totalAccount = await getTotalAccount(chains)
    // console.log("account in overview",totalAccount)
  }
   async function displayTotalaIssuance(){
    const totalIssuance = await getTotalIssuanxe(chains)
    const valueInssuance = parseInt(totalIssuance)
    // console.log("value inssuance", valueInssuance)
    setInssuance(valueInssuance)
  }

  // console.log("account", totalAccount) 
  useEffect(()=>{
    displayLatestBlock()
    displayFinalizedBlock()
    displayTotalaIssuance()    
    displayTotalAccount()
  },[])



  function issuancePrecision(issuance) {
    return toPrecision(issuance ?? 0, chainSetting.decimals);
  }

  return (
    <>
    <OverviewCardWrapper>
     <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
          <OverviewItem
            icon={<BlockSquareIcon />}
            label="Latest Blocks"
            value={currencify(overview.latestHeight)}
            // value={blockNumber}
          />
        </OverviewItemsWrapper>
      
      </OverviewPanel>
    </OverviewCard>
    <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
         <OverviewItem
            icon={<FinalizedBlockSquareIcon />}
            label="Finalized Block"
            value={currencify(overview.finalizedHeight)}
            // value={blockNumberFinalized}

          />
        </OverviewItemsWrapper>
      
      </OverviewPanel>
    </OverviewCard>
       <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
           <OverviewItem
            icon={<ExtrinsicsSquareIcon />}
            label="Signed Extrinsics"
            value={currencify(overview.signedExtrinsics)}
          />
        </OverviewItemsWrapper>
      
      </OverviewPanel>
    </OverviewCard>
    </OverviewCardWrapper>
    <OverviewCardWrapper>

    <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
          <OverviewItem
            icon={<TransferSquareIcon />}
            label="Transfers"
            value={currencify(overview.transfers)}
          />
        </OverviewItemsWrapper>
      
      </OverviewPanel>
    </OverviewCard>
     <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
          <OverviewItem
            icon={<AssetSquareIcon />}
            label={`Total Issuance (${chainSetting.symbol})`}
            value={
              <Tooltip
                tip={currencify(
                Number(issuancePrecision(overview.totalIssuance)),

                )}
              >
                <ValueDisplay
                  value={issuancePrecision(overview.totalIssuance)}
                />
              </Tooltip>
            }
          />
        </OverviewItemsWrapper>
      
      </OverviewPanel>
    </OverviewCard>
    <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
           <OverviewItem
            icon={<HolderSquareIcon />}
            label="Accounts"
            value={currencify(overview.accounts)}
          />
        </OverviewItemsWrapper>
      
      </OverviewPanel>
    </OverviewCard>
    </OverviewCardWrapper>
    
    {/* {modules?.uniques && ( */}
    {/* <OverviewCardWrapper>
     <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
           <OverviewItem
            icon={<NftSquareIcon />}
            label="NFT Class"
            tip="Recognized / All"
            value={currencify(overview.nftClasses?.valid)}
            total={currencify(overview.nftClasses?.total)}
          />
        </OverviewItemsWrapper>
      </OverviewPanel>
    </OverviewCard>
    <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
          <OverviewItem
            icon={<NftSquareIcon />}
            label="NFT Instance"
            tip="Recognized / All"
            value={currencify(overview.nftInstances?.valid)}
            total={currencify(overview.nftInstances?.total)}
          />
        </OverviewItemsWrapper>
      </OverviewPanel>
    </OverviewCard>
    </OverviewCardWrapper> */}
    {/* )} */}

     
    {modules?.assets || chainSetting.para && (

    <OverviewCardWrapper>
     <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
            {modules?.assets && (
            <OverviewItem
              icon={<AssetSquareIcon />}
              label="Assets"
              value={currencify(overview.assets)}
            />
          )}
        </OverviewItemsWrapper>
      </OverviewPanel>
    </OverviewCard>
    <OverviewCard>
      <OverviewPanel>
        <OverviewItemsWrapper>
         {chainSetting.para && (
            <OverviewItem
              icon={<ParaIdSquareIcon />}
              label={`${chainSetting.para.relay} Para ID`}
              value={currencify(chainSetting.para.id)}
            />
          )}
        </OverviewItemsWrapper>
      </OverviewPanel>
    </OverviewCard>
    </OverviewCardWrapper>

   )} 
    </>
   
  );
}

export default withLoading(mapLoadingState)(Overview);
